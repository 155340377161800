import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, NgControl, Validator } from '@angular/forms';
import { SfFormView, ValueAccessorBase } from '@hutsix/ngxh6';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sf-form-select-icon',
    templateUrl: './sf-form-select-icon.component.html',
    styleUrls: ['./sf-form-select-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormSelectIconComponent extends ValueAccessorBase implements Validator, OnChanges {
    public objectKeys = Object.keys;

    @Input() public view!: SfFormView;

    public inputValue: any[] = [];
    public choices: any;
    public multiple?: boolean;

    constructor(public cdRef: ChangeDetectorRef, public ngControl: NgControl) {
        super(cdRef, ngControl);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('view' in changes) this.setup();
        super.ngOnChanges(changes);
    }

    private setup(): void {
        this.choices = this.view?.vars?.choices;
        this.multiple = this.view?.vars?.multiple;
    }

    //
    // FORM ACTIONS
    //
    isSelectedGroup(choices: { [key: string]: any }): boolean {
        for (const key in choices) {
            if (Object.prototype.hasOwnProperty.call(choices, key)) {
                const choice = choices[key];
                if (choice.choices && !this.isSelectedGroup(choice.choices)) {
                    return false;
                } else if (!this.isSelected(choice.value)) {
                    return false;
                }
            }
        }
        return true;
    }

    isSelected(value: any): boolean {
        return !(this.inputValue.findIndex(x => x === value) === -1);
    }

    checkGroup(c: object): void {
        if (!this.multiple) {
            return;
        }

        this.onTouched();

        let action = 'add';
        if (this.isSelectedGroup(c)) {
            action = 'remove';
        }

        const doCheckGroup = (choices: any) => {
            for (const key in choices) {
                if (Object.prototype.hasOwnProperty.call(choices, key)) {
                    const choice = choices[key];
                    if (choice.choices) {
                        doCheckGroup(choices);
                    } else {
                        this.checkValue(choice.value, action);
                    }
                }
            }
        };

        doCheckGroup(c);
    }

    checkValue(value: any, action?: string): void {
        this.onTouched();

        if (!this.multiple) {
            this.inputValue = this.isSelected(value) ? [] : [value];
            this.onInputChange();
            return;
        }

        if (this.isSelected(value)) {
            this.removeValue(value);
            return;
        }

        this.addValue(value);
    }

    addValue(value: any): void {
        let index: number;
        if (typeof value === 'object') {
            index = this.inputValue.findIndex(x => x.id === value.id);
        } else {
            index = this.inputValue.findIndex(x => x === value);
        }

        if (index === -1) {
            this.inputValue.push(value);
            this.onInputChange();
        }
    }

    removeValue(value: any): void {
        let index: number;
        if (typeof value === 'object') {
            index = this.inputValue.findIndex(x => x.id === value.id);
        } else {
            index = this.inputValue.findIndex(x => x === value);
        }

        if (index > -1) {
            this.inputValue.splice(index, 1);
            this.onInputChange();
        }
    }

    //
    // CVA ACTIONS
    //
    writeValue(value: any): void {
        if (value === undefined || value === null) value = [];

        let newValue: any[] = this.multiple && Array.isArray(value) ? value : [value];
        newValue = newValue.map(v => (v.id ? v.id : v));

        this.inputValue = newValue;
    }

    onInputChange(): void {
        this.onChange(this.multiple ? this.inputValue : this.inputValue[0] || null);
    }

    public validate(c: UntypedFormControl): any {
        // console.log(this.ngControl.value);
        if (this.view?.vars?.required && (!this.ngControl.value || !this.ngControl.value.length)) {
            return ['Field is required'];
        }

        return null;
    }
}
