import cruds_conf from '../_generated/ext_config/cruds.json';
import { CrudOptions } from '@hutsix/ngxh6';

/** Override generated CRUD values here */
const cruds_conf_overrides: Override = {
  // MacYouth
  tags: {
    objectLabel: 'Tag',
    formSlug: 'tag',
    icon: 'fa-file-lines',
    queryKey: 'ta',
    queryExtras: { pagination: 0 },
    apiClassName: 'App\\Entity\\MacYouth\\Tag',
    archivable: true,
    tableSpec: {
      columns: [
        { header: 'Name', value: 'name', style: { width: '50%' } },
        { header: 'Label', value: 'label', style: { width: '50%' } },
      ],
    },
  },
  policies: {
    objectLabel: 'Policy',
    formSlug: 'policy',
    icon: 'fa-file-lines',
    queryKey: 'pl',
    queryExtras: { pagination: 0 },
    apiClassName: 'App\\Entity\\MacYouth\\Policy',
    archivable: true,
    searchable: true,
    sortable: true,
    tableSpec: {
      columns: [{ header: 'Title', value: 'title', style: { width: '100%' } }],
    },
  },
  calendar_events: {
    objectLabel: 'Event',
    title: 'Calendar Events',
    formSlug: 'calendar_event',
    queryKey: 'env',
    apiClassName: 'App\\Entity\\MacYouth\\CalendarEvent',
    archivable: true,
    searchable: true,
    filterable: true,
    tableSpec: {
      // hideActions: true,
      columns: [
        { header: '', value: 'colour', type: 'colour' },
        { header: 'Start', value: 'start', type: 'date', typeOption: 'mediumDate', class: 'text-nowrap', orderBy: 'start' },
        { header: 'End', value: 'end', type: 'date', typeOption: 'mediumDate', class: 'text-nowrap' },
        { header: 'Event', value: 'string', style: { 'min-width': '350px', 'width': '100%' } },
      ],
    },
  },
  permission_specs: {
    objectLabel: 'Form',
    formSlug: 'permission_spec',
    queryKey: 'ps',
    apiClassName: 'App\\Entity\\MacYouth\\PermissionSpec',
    archivable: true,
    searchable: true,
    tableSpec: {
      columns: [{ header: 'Name', value: 'name', style: { width: '100%' } }],
    },
  },
  communities: {
    title: 'Communities',
    objectLabel: 'Community',
    formSlug: 'community',
    queryKey: 'comm',
    apiClassName: 'App\\Entity\\MacYouth\\Community',
    queryExtras: { pagination: false },
    searchable: true,
    tableSpec: {
      columns: [
        { header: 'Name', value: 'name', style: { width: '50%' } },
        { header: 'English Name', value: 'englishName', style: { width: '50%' } },
      ],
    },
  },
  case_management_issues: {
    objectLabel: 'Case Management Issue',
    formSlug: 'case_management_issue',
    queryKey: 'ci',
    apiClassName: 'App\\Entity\\MacYouth\\CaseManagementIssue',
    queryExtras: { pagination: false },
    archivable: true,
    tableSpec: {
      columns: [{ header: 'Name', value: 'name', style: { width: '50%' } }],
    },
  },
  facilities: {
    objectLabel: 'Facility',
    formSlug: 'facility',
    queryKey: 'fac',
    apiClassName: 'App\\Entity\\MacYouth\\Facility',
    queryExtras: { pagination: false },
    archivable: true,
    tableSpec: {
      columns: [{ header: 'Name', value: 'name', style: { width: '50%' } }],
    },
  },
  funding_sources: {
    title: 'Funding Sources',
    objectLabel: 'Funding Source',
    formSlug: 'funding_source',
    queryKey: 'fac',
    apiClassName: 'App\\Entity\\MacYouth\\FundingSource',
    queryExtras: { pagination: false },
    archivable: true,
    tableSpec: {
      columns: [{ header: 'Name', value: 'name', style: { width: '50%' } }],
    },
  },
  activity_specs: {
    title: 'Activity Types',
    objectLabel: 'Activity Type',
    formSlug: 'activity_spec',
    queryKey: 'as',
    apiClassName: 'App\\Entity\\MacYouth\\ActivitySpec',
    queryExtras: { pagination: false },
    archivable: true,
    tableSpec: {
      columns: [
        { header: 'Name', value: 'name' },
        { header: 'Tag', value: 'activityGroup', type: 'objvalue', typeOption: 'label' },
        { header: 'Location', value: 'collectLocation', type: 'boolean' },
        { header: 'Target Group', value: 'collectTargetGroup', type: 'boolean' },
        { header: 'Stakeholder', value: 'collectEventStakeholder', type: 'boolean' },
        { header: 'Multi-Community', value: 'multipleCommunities', type: 'boolean' },
        { header: 'Multi-Day', value: 'multiDay', type: 'boolean' },
        { header: 'Disruption', value: 'serviceDisruption', type: 'boolean' },
      ],
    },
  },
  policy_categories: {
    title: 'Policies Categories',
    objectLabel: 'Policies Category',
    formSlug: 'policy_category',
    queryKey: 'as',
    apiClassName: 'App\\Entity\\MacYouth\\PolicyCategory',
    queryExtras: { pagination: false },
    archivable: true,
    tableSpec: {
      columns: [{ header: 'Name', value: 'name' }],
    },
  },
  agenda_item_templates: {
    title: 'Agenda Item Templates',
    objectLabel: 'Agenda Item Template',
    formSlug: 'agenda_item_template',
    apiClassName: 'App\\Entity\\MacYouth\\AgendaItemTemplate',
    queryExtras: { pagination: false },
    archivable: false,
    tableSpec: {
      columns: [{ header: 'Community', value: 'community', type: 'objvalue', typeOption: 'string' }],
    },
  },
};

/** Create custom CRUDS or override generated forms here */
const cruds = {
  // test: new CrudOptions({
  //   objectLabel: 'Test',
  //   apiEndpoint: '/api/v1/examples',
  // }),
};

/**
 *
 * You shouldn't need to change anything below
 *
 */
export function getCrudsConfig() {
  const c = JSON.parse(JSON.stringify(cruds_conf));
  for (const [k, v] of Object.entries(cruds_conf_overrides)) {
    if (c[k]) {
      c[k] = Object.assign(cruds_conf[k] || {}, v);
    } else {
      console.warn(`Attempt to override generated CRUD failed. Key '${k}' doesn't exist`);
    }
  }
  return { ...c, ...cruds };
}

export function getCrud(slug: CrudKeys): CrudOptions {
  return getCrudsConfig()[slug];
}

declare global {
  type CrudKeys = keyof typeof cruds | keyof typeof cruds_conf;
}

type Override = { [Property in keyof Partial<typeof cruds_conf>]: Partial<CrudOptions> };
