<div class="d-none d-sm-flex row mb-3 header">
    <div class="col-12 col-sm-2 col-lg-2 offset-lg-2 d-flex align-items-center justify-content-center">
        <h5 class="label m-0 font-weight-bold">Age</h5>
    </div>
    <div class="d-none d-sm-block col-sm-5 col-lg-3 female">
        <h5 class="d-block label m-0 font-weight-bold text-center">
            <i class="fa fa-person-dress me-2"></i>
            Female
        </h5>
    </div>
    <div class="d-none d-sm-block col-sm-5 col-lg-3 male">
        <h5 class="d-block label m-0 font-weight-bold text-center">
            <i class="fa fa-person me-2"></i>
            Male
        </h5>
    </div>
</div>

<hr class="d-none d-sm-block" />

<ng-container *ngFor="let item of choices">
    <div class="row mb-4 mb-sm-3">
        <div class="col-12 col-sm-2 col-lg-2 offset-lg-2 d-flex align-items-center justify-content-center">
            <h5 class="label m-0 font-weight-bold mb-4 mt-2 my-sm-0"><span class="d-inline d-sm-none">Age </span>{{ item.label }}</h5>
        </div>

        <div class="col-6 col-sm-5 col-lg-3 female">
            <h6 class="d-block d-sm-none label font-weight-bold text-center">
                <i class="fa fa-female me-2"></i>
                Female
            </h6>
            <div class="spinner d-flex align-items-center justify-content-center">
                <i class="fa fa-3x fa-minus px-3" (click)="minus(item.choice + 'f')"></i>
                <input [ngModel]="inputValue[item.choice + 'f']" type="text" class="female form-control form-control-lg" readonly="readonly" />
                <i class="fa fa-3x fa-plus px-3" (click)="add(item.choice + 'f')"></i>
            </div>
        </div>
        <div class="col-6 col-sm-5 col-lg-3 male">
            <h6 class="d-block d-sm-none label font-weight-bold text-center">
                <i class="fa fa-person me-2"></i>
                Male
            </h6>
            <div class="spinner d-flex align-items-center justify-content-center">
                <i class="fa fa-3x fa-minus px-3" (click)="minus(item.choice + 'm')"></i>
                <input [ngModel]="inputValue[item.choice + 'm']" type="text" class="male form-control form-control-lg" readonly="readonly" />
                <i class="fa fa-3x fa-plus px-3" (click)="add(item.choice + 'm')"></i>
            </div>
        </div>
    </div>
    <hr class="d-block d-sm-none" />
</ng-container>
