<div class="input-group" ngbDropdown>
    <span class="preview text-muted input-group-text" [ngStyle]="{ 'background-color': inputValue }">
        <i class="fa fa-fw fa-xmark" *ngIf="!inputValue"></i>
    </span>

    <input
        class="form-control"
        #formInput="ngModel"
        [id]="view.vars.full_name"
        [(ngModel)]="inputValue"
        (ngModelChange)="onChange(inputValue)"
        [type]="type"
        [required]="view.vars.required"
        [maxlength]="view.vars.attr.maxlength"
        [minlength]="view.vars.attr.minlength"
        [disabled]="view.vars.disabled"
        [max]="view.vars.attr.max"
        [min]="view.vars.attr.min"
        [pattern]="view.vars.attr.pattern"
        [class.errors]="view.vars.errors.length"
        [attr.placeholder]="view.vars.attr.placeholder || 'Pick Colour'"
    />

    <a class="btn btn-outline-gray no-caret" id="actionsDropdown" ngbDropdownToggle *ngIf="!view.vars.disabled">
        <i class="fa fa-eye-dropper"></i>
    </a>

    <div ngbDropdownMenu aria-labelledby="actionsDropdown" class="dropdown-menu dropdown-menu-right" role="menu">
        <compact-picker [(color)]="inputValue" (colorChange)="colorChange()"></compact-picker>
    </div>
</div>
